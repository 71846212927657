.shows-list__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.show-list__heading {
    font-weight: bold;
    font-size: calc(1.2rem);
    padding: 6px 12px;
    color: #E20177;
}

.search--container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    font-size: 16px;

    margin-bottom: 0.5rem;
}

@media screen and (max-width: 850px) {
    .search--container {
        justify-content: center;
    }
}

.search--field {
    padding: 0.2rem 0.3rem;
    border: 2px solid #e2017579;
    outline: none;
    border-radius: 0.5rem;
}

.search--field:focus,
.search--field:active {
    border-color: #e20175;
}

.search--button {
    padding: 0.2rem 0.5rem;
    background-color: #e20175d8;
    color: white;
    border: 2px solid #e20175;
    outline: none;
    font-weight: 500;
    border-radius: 0.5rem;
    cursor: pointer;
}

.search--button:hover {
    background-color: #e20175;
}

.shows-list__container .text--center {
    text-align: center;
}

.shows-list__container .text--primary {
    color: #e20175;
}

.shows-list__container .text--big {
    font-size: 1.6rem;
}

.shows-list__container--empty {
    width: 100%;
    margin: 1rem;
}