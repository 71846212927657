.shows-container {
    position: relative;
    padding-top: 120px;
    display: flex;
    padding-inline: 125px;
    gap: 10px;
    width: 100%;
}

@media only screen and (max-width: 1030px) {
    .shows-container {
        padding-inline: 50px;
    }
}

@media only screen and (max-width: 850px) {
    .shows-container {
        display: block;
    }
}

@media only screen and (max-width: 850px) {
    .shows-container {
        /* top: 70px; */
        padding-inline: 25px;
    }
}

.flex--1 {
    flex: 1;
}

.flex--2 {
    flex: 3
}

.shows_page_container {
    display: block;
}