* {
	margin: 0;
	padding: 0;
}

@font-face {
	font-family: FrutigerLTLight;
	src: url('assets/fonts/Frutiger LT 47 Light Condensed.ttf');
}

@font-face {
	font-family: FrutigerLTLightItalic;
	src: url('assets/fonts/Frutiger LT 48 Light Condensed Italic.ttf');
}

@font-face {
	font-family: FrutigerLT;
	src: url('assets/fonts/Frutiger LT 57 Condensed.ttf');
}

@font-face {
	font-family: FrutigerLTItalic;
	src: url('assets/fonts/Frutiger LT 58 Condensed Italic.ttf');
}

@font-face {
	font-family: FrutigerLTBold;
	src: url('assets/fonts/Frutiger LT 67 Bold Condensed.ttf');
}

@font-face {
	font-family: FrutigerLTBoldItalic;
	src: url('assets/fonts/Frutiger LT 68 Bold Condensed Italic.ttf');
}
