.shows-card__outer-container {
    width: 210px;
    /* height: 328px; */
    margin: 12px;
    /* margin: 12px 24px; */
    -webkit-box-shadow: 3px -2px 10px -3px rgba(148, 148, 148, 1);
    -moz-box-shadow: 3px -2px 10px -3px rgba(148, 148, 148, 1);
    box-shadow: 3px -2px 10px -3px rgba(148, 148, 148, 1);
    border-radius: 5px;
    background-color: #ffffff;
    cursor: pointer;
    transition: scale ease-in-out 0.1s;
}

.shows-card__outer-container:hover {
    scale: 1.01;
}

.shows-img-container {
    width: 210px;
    /* height: 180px; */
    aspect-ratio: 1;
    margin-bottom: 14px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #f6f5f5;
}

.shows-img {
    width: 100%;
    aspect-ratio: 1;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.shows-heading {
    font-size: 16px;
    font-weight: 600;
    color: #504f4f;
}

.shows-creator {
    font-size: 14px;
    font-weight: 300;
    margin: 0px 10px 5px 10px;
    color: #504f4f;
}

.episode-count {
    font-size: 12px;
    color: #787878;
    font-weight: 400;
}

.text-align--center {
    margin: 0px 10px;
}