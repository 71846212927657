.bottom--footer_container {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    height: max-content;
    padding: 10px 10px;
}

.bottom--footer_text {
    text-align: center;
}

.footer--text_small {
    font-size: 0.7rem;
}

.bottom--footer_link {
    color: #E20177;
    text-decoration: none;
}

.bottom--footer_link:hover {
    color: #1B4298;
}

@media screen and (min-width: 580px) {
    .bottom--footer_container {
        padding: 10px 30px;
    }
}

@media screen and (min-width: 850px) {
    .bottom--footer_container {
        padding: 10px 40px;
    }

    .footer--text_small {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 1200px) {
    .bottom--footer_container {
        padding: 10px 80px;
    }
}