.episode-box__outer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}

.episode-box {
        width: 60vw;
        /* margin-right: 10%; */
        align-items: center;
        -webkit-box-shadow: 3px -2px 10px -3px rgba(148, 148, 148, 1);
        -moz-box-shadow: 3px -2px 10px -3px rgba(148, 148, 148, 1);
        box-shadow: 3px -2px 10px -3px rgba(148, 148, 148, 1);
        margin-bottom: 32px;
        border-radius: 20px;
}

@media only screen and (max-width: 850px) {
        .episode-box__outer-container {
                display: block;
                padding: -10px -20px;
                margin-top: 32px;
        }

        .episode-box {
                padding: -10px -20px;
                width: 88vw;
                min-width: 250px;
        }
}