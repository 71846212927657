html,
body {
	width: 100%;
}

.app--main--container {
	min-height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	position: relative;
	padding-bottom: 100px;
}

@media screen and (min-width: 800px) {
	.app--main--container {
		padding-bottom: 80px;
	}
}

.app-wrapper {
	position: relative;
	padding-top: 8%;
}