.app__header {
    position: fixed;
    max-height: 230px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 7px 0px;
    width: 100vw;
    z-index: 10;
    padding: 12px 0 12px 125px;
    text-align: left;
}

@media only screen and (max-width: 1030px) {
    .app__header {
        padding: 12px 12px 12px;
    }
}

.app__header--logo {
    width: 200px;
    cursor: pointer;
}

.app_header__action {
    float: right;
    margin-right: 10%;
    margin-top: 3%;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 900px) {
    .app__header {
        max-height: 100px;
        width: 100%;
    }

    .app__header--logo {
        width: 150px;
    }

    .app_header__action {
        font-weight: 400 !important;
        font-size: 10px;
        margin-top: 3%;
    }
}