.login {
	font-family: FrutigerLT;
	width: 100%;
}

.app__header {
	padding: 10px 20px !important;
}

.login__container {
	width: 100%;
	min-height: 80vh;
	padding-inline: 20px;
	padding-top: 60px;
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	align-items: center;
	justify-content: space-between;
	align-content: center;
}

.login__column1,
.login__column2 {
	display: block;
	box-sizing: border-box;
	margin-bottom: 30px;
}

.login__column1 {
	width: 100%;
}

.login__content {
	color: #E20177;
	padding-bottom: 10px;
}

.login__content h1 {
	font-weight: bold;
	font-size: calc(1.25rem + 1.2vw);
}

.login__content p {
	font-weight: normal;
}

.login__action {
	color: #ffffff;
	background: #1B4298;
	/* color: #E20177; */
	padding: 10px 15px;
	border-radius: 3px;
	border: unset;
	box-shadow: unset;
	cursor: pointer;
	font-weight: 600;
}

.login__column2 {
	width: 100%;
}

.login__placeholder {
	width: 100%;
}

@media screen and (min-width: 900px) {
	.login__container {
		padding-top: 0px;
		padding-inline: 60px;
	}

	.app__header {
		padding: 16px 60px !important;
	}

	.login__column1,
	.login__column2 {
		width: 50%;
		/* padding: 20px; */
		text-align: left;
	}

	.login__content {
		max-width: 90%;
	}

	.login__content {
		padding-bottom: 20px;
	}
}

@media screen and (min-width: 1200px) {
	.login__container {
		padding-top: 0px;
		padding-inline: 100px;
	}

	.app__header {
		padding: 16px 100px !important;
	}

	.login__content {
		max-width: 80%;
	}

	.login__content {
		padding-bottom: 28px;
	}
}

@media screen and (min-width: 1400px) {
	.login__container {
		padding-top: 0px;
		padding-inline: 160px;
	}

	.app__header {
		padding: 16px 160px !important;
	}

	.login__content {
		max-width: 75%;
	}
}

/* 
@media (max-width: 900px) {

	.login__column1,
	.login__column2 {
		display: block;
		width: 90%;
		margin: 50px auto 20px auto;
		text-align: center;
	}
} */