.shows-category__container {
    width: 100%;
    padding: 12px;
}

.show-category__titile,
.show-category__description {
    color: #E20177;
}

.show-category__description {
    font-size: 0.9rem;
    text-align: center;
}

.show-category__titile {
    font-size: 1.4rem;
    text-align: center;
    font-weight: bolder;
}

@media (min-width: 851px) {
    .show-category__description {
        text-align: justify;
    }

    .show-category__titile {
        text-align: left;
    }
}

.shows-category__container .heading {
    margin-bottom: 40px;
    color: rgb(93, 90, 90);
    font-size: 14px;
    font-weight: 500;
}

.category-list {
    min-width: 260px;
    max-width: 300px;
    display: none;
}

@media (min-width: 900px) {
    .category-list {
        display: block;
    }
}

.category-list img {
    max-width: 100%;
}

.category-list .bullet-icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-left: 10px;
    color: #b0b0b0;
    font-weight: 200;
    font-size: 20px;
}

.category-list .category-name {
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    font-weight: 300;
    padding-top: 5px;
}

.category-list .list-items {
    display: flex;
    border-bottom: 1px solid #d5d3d3;
    padding-bottom: 14px;
    max-width: 400px;
    cursor: pointer;
}