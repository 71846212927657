.show-details__container {
    display: flex;
    height: fit-content;
    flex-wrap: wrap;
}

.show-details__image {
    width: 200px;
    aspect-ratio: 1;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
}

.show-details__details {
    width: 100%;
    display: block;
    position: relative;
    padding: 20px 0;
}

.show-details-img {
    width: 100%;
    aspect-ratio: 1;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
}

.show-details__title {
    font-size: 16px;
    /* margin-top: 32px; */
    color: #090808;
    font-weight: 600;
}

.show-details__description {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #323232;
    text-align: justify;
    padding-right: 0;
}

@media only screen and (max-width: 500px) {}

@media only screen and (max-width: 850px) {

    .show-details__image {
        width: 200px;
        height: 200px;
    }

    /* .show-details__details {
        width: auto;
        padding: 0 20px;
    } */

    .show-details__description {
        padding-right: 14px;
    }
}

@media only screen and (max-width: 1030px) {

    .show-details__image {
        width: 200px;
    }

    .show-details__details {
        width: 100%;
    }
}


.show-details__container .image {
    width: 240px;
    height: 240px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
}

@media only screen and (max-width: 1030px) {
    .show-details__container .image {
        width: 200px;
        height: 200px;
    }
}

@media only screen and (max-width: 850px) {
    .show-details__container {
        display: flex;
    }

    .show-details__container .details {
        margin-left: 32px;
        margin-right: 32px;
    }
}

@media only screen and (max-width: 500px) {
    .show-details__container {
        display: block;
    }

    .show-details__container .image {
        width: 250px;
        height: 250px;
    }

    .show-details__container .details {
        margin: 0;
    }
}

.show-details__container .title {
    font-size: 16px;
    margin-top: 32px;
    color: #000000;
    font-weight: 600;
}

.show-details__container .description {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 300;
    color: #4b4a4a;
    text-align: justify;
    padding-right: 14px;
}